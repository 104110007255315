<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="p-1">
        <b-row>
          <b-col
            v-if="$can('create', 'notification')"
            class="col d-flex justify-content-end"
          >
            <b-button variant="primary" @click="createNotification">
              <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refNotificationsListTable"
        class="position-relative table-white-space"
        :items="fetchNotifications"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(content)="data">
          <b-link
            class="cursor-pointer text-white"
            @click="showNotificationDetail(data.item)"
          >
            {{ data.item.content.substring(0, 50) }}
          </b-link>
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Detail')"
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
            @click="showNotificationDetail(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('delete', 'notification')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deleteNotification(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNotifications"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add Notification Modal -->
    <add-notification-modal @refetch-data="refetchData" />

    <!-- Modal Notification Detail -->
    <notification-detail-modal :notification-data.sync="notificationData" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText, numberFormat, formatDateTime } from "@core/utils/filter";
import useNotificationsList from "./useNotificationsList";
import frontendStoreModule from "../frontendStoreModule";

export default {
  components: {
    AddNotificationModal: () => import("./AddNotificationModal.vue"),
    NotificationDetailModal: () => import("./NotificationDetailModal"),
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      notificationData: null,
    };
  },
  methods: {
    createNotification() {
      this.$bvModal.show("modal-add-notification");
    },
    deleteNotification(notificationId) {
      this.$swal({
        title: "Bạn có chắc không ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const notificationData = {
            id: notificationId,
          };
          store
            .dispatch("frontend/deleteNotification", notificationData)
            .then((response) => {
              if (response.data.code == "00") {
                this.$swal({
                  icon: "success",
                  title: "Deleted!",
                  text: "Your file has been deleted.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.refetchData();
              }
            });
        }
      });
    },
    showNotificationDetail(datas) {
      this.notificationData = datas;
      this.$bvModal.show("modal-notification-detail");
    },
  },
  setup() {
    const FRONTEND_MODULE_NAME = "frontend";
    if (!store.hasModule(FRONTEND_MODULE_NAME))
      store.registerModule(FRONTEND_MODULE_NAME, frontendStoreModule);
    onUnmounted(() => {
      if (store.hasModule(FRONTEND_MODULE_NAME))
        store.unregisterModule(FRONTEND_MODULE_NAME);
    });
    const {
      fetchNotifications,
      tableColumns,
      perPage,
      currentPage,
      totalNotifications,
      dataMeta,
      refNotificationsListTable,
      refetchData,
      resolveStatus,
    } = useNotificationsList();

    return {
      fetchNotifications,
      tableColumns,
      perPage,
      currentPage,
      totalNotifications,
      dataMeta,
      refNotificationsListTable,
      refetchData,
      resolveStatus,
      numberFormat,
      formatDateTime,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
